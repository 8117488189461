<template>
    <v-card hover class="ma-0 pa-0">
        <v-card-title style="background-color:#4E87DD; color:#FFFFFF; height:70px;">
            <v-row no-gutters>
                <v-col class="pt-1 pl-4 text-h5 font-weight-bold">연락처 등록</v-col>
            </v-row>
        </v-card-title>

        <v-card-text class="mt-3 pb-0 pl-7 pr-7 pt-3">
            <v-row no-gutters>
                <v-col class="pt-0 pb-0 title" style="color:#999999">연락처<span style="color:red; padding-left:5px;">*</span></v-col>
            </v-row>
            <v-row no-gutters>
                <v-col class="pt-2 pb-4">
                    <num-text-field outlined hide-details :error="formStatus['cust_mdn'].err" :ref="'cust_mdn'" placeholder="연락처를 입력해 주세요" v-model="formData.cust_mdn" @keyup="checkChanged('cust_mdn')"
                                    @change="checkChanged('cust_mdn', true)" :disabled="popupData.addrBook.cust_mdn && popupData.addrBook.cust_mdn.length != 0">
                        <template v-slot:append v-if="formStatus['cust_mdn'].err_msg">
                            <div style="color:#FF4D4D; font-size:13px; padding-top:5px;">
                                {{ formStatus['cust_mdn'].err_msg }}
                            </div>
                        </template>
                    </num-text-field>
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-col class="pb-0 title" style="color:#999999">이름(표시명)<span style="color:red; padding-left:5px;">*</span></v-col>
            </v-row>
            <v-row no-gutters>
                <v-col class="pt-2 pb-4">
                    <v-text-field outlined hide-details :error="formStatus['cust_name'].err" :ref="'cust_name'" placeholder="이름(표시명)을 입력해 주세요" v-model="formData.cust_name"
                                  @keyup="checkChanged('cust_name')" @change="checkChanged('cust_name', true)">
                        <template v-slot:append v-if="formStatus['cust_name'].err_msg">
                            <div style="color:#FF4D4D; font-size:13px; padding-top:5px;">
                                {{ formStatus['cust_name'].err_msg }}
                            </div>
                        </template>
                    </v-text-field>
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-col class="pt-0 pb-0 title" style="color:#999999">비고</v-col>
            </v-row>
            <v-row no-gutters>
                <v-col class="pt-2 pb-0">
                    <v-text-field outlined hide-details :error="formStatus['cust_desc'].err" :ref="'cust_desc'" placeholder="비고사항을 입력해 주세요" v-model="formData.cust_desc"
                                  @keyup="checkChanged('cust_desc')" @change="checkChanged('cust_desc', true)">
                        <template v-slot:append v-if="formStatus['cust_desc'].err_msg">
                            <div style="color:#FF4D4D; font-size:13px; padding-top:5px;">
                                {{ formStatus['cust_desc'].err_msg }}
                            </div>
                        </template>
                    </v-text-field>
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-actions class="ma-0 pa-0">
            <v-row no-gutters>
                <v-col cols="12" class="pr-5 pb-6 pt-5 text-right">
                    <v-btn class="ma-2" width="130px" color="primary" text v-on:click="closePopup()"><span style="color:#555555; font-size:1.4em;">취소</span></v-btn>
                    <v-btn class="ma-2 white--text" width="130px" color="#4573D5" large v-on:click="submitPopup()"><span style="font-size:1.4em;">확인</span></v-btn>
                </v-col>
            </v-row>
        </v-card-actions>
    </v-card>
</template>
<script>

import {mapGetters, mapState} from 'vuex'
import addrBookApi from '@/api/addr_book'

export default {
    components: {
        'numTextField': () => import('@/components/common/num_text_field.vue'),
    },
    data: () => ({
        formData: {
            addr_book_seqno: 0,
            cust_name: '',
            cust_mdn: '',
            cust_desc: ''
        },
        formStatus: {
            'cust_name': {
                err: false,
                err_msg: '',
            },
            'cust_mdn': {
                err: false,
                err_msg: '',
            },
            'cust_desc': {
                err: false,
                err_msg: '',
            }
        },
        pageMode: 'add'
    }),
    computed: {
        ...mapGetters({
            popupModal: 'popupModal',
            popupType: 'popupType',
            popupData: 'popupData',
        }),
    },
    created() {
    },
    mounted() {
        let addrBook = this.popupData.addrBook

        if (addrBook && Object.keys(addrBook).length != 0) {
            this.formData.addr_book_seqno = addrBook.addr_book_seqno
            this.formData.cust_name = addrBook.cust_name
            this.formData.cust_mdn = addrBook.cust_mdn
            this.formData.cust_desc = addrBook.cust_desc
            this.pageMode = addrBook.pageMode
        }
    },
    methods: {
        closePopup() {
            this.$emit('close')
        },
        closeAndReload() {
            this.$emit('ok', true)
        },
        submitPopup() {
            if (this.checkFormValid() == false) {
                return
            }

            let userMdn = this.formData.cust_mdn.replaceAll(/[^0-9]/g, '')

            let reqForm = {
                'addr_book_seqno': this.pageMode == 'modify' ? this.formData.addr_book_seqno : 0,
                'cust_name': this.formData.cust_name,
                'cust_mdn': userMdn,
                'cust_desc': this.formData.cust_desc
            }

            if (this.pageMode == 'modify') {
                addrBookApi.updateAddrBook(
                    reqForm,
                    (va) => {
                        va.closeAndReload()
                    },
                    (err, va) => {
                        va.$store.dispatch('openAlert', {
                            'message': '처리 중 오류가 발생하였습니다.'
                        })
                    },
                    this
                )
            } else {
                addrBookApi.checkExitsCustMdn(
                    {'cust_mdn': userMdn},
                    (existsYn, va) => {
                        if (existsYn == 'Y') {
                            va.formStatus.cust_mdn.err = true
                            va.formStatus.cust_mdn.err_msg = '이미 사용중인 연락처입니다.'
                        } else if (existsYn == 'N') {
                            addrBookApi.createAddrBook(
                                reqForm,
                                (va) => {
                                    va.closeAndReload()
                                },
                                (err, va) => {
                                    va.$store.dispatch('openAlert', {
                                        'message': '처리 중 오류가 발생하였습니다.',
                                        'sub_message': err == 'ALREADY_EXISTS' ? '이미 사용중인 연락처입니다.' : ''
                                    })
                                },
                                va
                            )
                        }
                    },
                    (err, va) => {
                        console.log(err)
                        this.$store.dispatch('openAlert', {
                            'message': '처리 중 오류가 발생하였습니다'
                        })
                    },
                    this
                )
            }

        },
        checkChanged(t, focusOutFlag) {
            if (this.formStatus[t]) {
                if (this.formStatus[t].err || focusOutFlag) {
                    this.checkFormValid(t)
                }
            }
        },
        checkFormValid(t) {
            let succ = true
            if (!t || t == 'cust_name') {
                if (this.formData.cust_name == '') {
                    this.formStatus.cust_name.err = true
                    this.formStatus.cust_name.err_msg = '이름(표시명)을 입력해 주세요'
                    succ = false
                } else if (this.formData.cust_name.length > 20) {
                    this.formStatus.cust_name.err = true
                    this.formStatus.cust_name.err_msg = '최대 20자까지 입력 가능합니다'
                    succ = false
                } else {
                    this.formStatus.cust_name.err = false
                    this.formStatus.cust_name.err_msg = ''
                }
            }

            if (!t || t == 'cust_mdn') {
                if (this.formData.cust_mdn == '') {
                    this.formStatus.cust_mdn.err = true
                    this.formStatus.cust_mdn.err_msg = '연락처를 입력해 주세요'
                    succ = false
                } else {
                    this.formStatus.cust_mdn.err = false
                    this.formStatus.cust_mdn.err_msg = ''

                }
            }

            if (!t || t == 'cust_desc') {
                if (this.formData.cust_desc && this.formData.cust_desc.length > 100) {
                    this.formStatus.cust_desc.err = true
                    this.formStatus.cust_desc.err_msg = '최대 100자까지 입력 가능합니다'
                    succ = false
                } else {
                    this.formStatus.cust_desc.err = false
                    this.formStatus.cust_desc.err_msg = ''
                }
            }

            return succ
        }
    }
}
</script>
